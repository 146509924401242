<template>
  <div style="height: 100%" v-loading="loading">
    <iframe ref="iframe" :src="origin+'/#/single'" width="100%" height="100%">信息总览</iframe>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      origin: window.location.origin,
      loading: true
    }
  },
  mounted() {
    this.iframeLoad();
  },
  methods: {
    // iframe-loading方法
    iframeLoad() {
      let self = this;
      const iframe = this.$refs.iframe;
      // 兼容处理
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent("onload", () => {
          self.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          self.loading = false;
        };
      }
    },
  },
}
</script>

<style scoped>

</style>
